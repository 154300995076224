import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { MdClose } from "react-icons/md";
import "./Navbar.css";
import Logo from "../../Images/Logo.png";
import Call from "../../Images/call.png";
import Hamburger from "../../Images/Humberders.png";
import { HiChevronRight } from "react-icons/hi";

const NavBar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="header">
      <div className="logo">
        <Link to="/">
          <img src={Logo} className="logoImg" alt="img" />
        </Link>
      </div>

      <div className="navbar">
        <Drawer />
      </div>
      <div className="navbar_number">
        <div className="callImg">
          <img src={Call} alt="call img" />
        </div>
        <div>
          <div className="callText">Call Us on</div>
          <div className="num">+44 123 456 7890</div>
        </div>
      </div>

      <div className="humburgerLogo">
        {click ? (
          <MdClose
            size={40}
            style={{ color: "#fff" }}
            onClick={handleClick}
          ></MdClose>
        ) : (
          <img src={Hamburger} alt="humburrgerLogo" onClick={handleClick} />
        )}

        <div
          className={`hamburger_btn_manu ${
            click ? "drawer_open" : "drawer_close"
          }`}
        >
          <div className="drawer_container">
            <div className="drawer_btn_container">
              <Link>
                <button className="button">
                  <span className="btn_text">Log in</span>
                  <HiChevronRight className="rightangle_icon" />
                </button>
              </Link>
              <Link>
                <button className="button">
                  <span className="btn_text">Sign Up</span>
                  <HiChevronRight className="rightangle_icon" />
                </button>
              </Link>
            </div>
            <div className="drawer_manu">
              <Drawer handleClick={handleClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NavBar;

const Drawer = (props) => {
  return (
    <ul className="nav-manu">
      <li>
        <Link
          to="home"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          onClick={props.handleClick}
        >
          Home
        </Link>
      </li>
      <li>
        <Link
          to="about"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          onClick={props.handleClick}
        >
          About Us
        </Link>
      </li>
      <li>
        <Link
          to="service"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          onClick={props.handleClick}
        >
          Our Services
        </Link>
      </li>
      <li>
        <Link
          to="portfolio"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          onClick={props.handleClick}
        >
          Portfolio
        </Link>
      </li>
      <li>
        <Link
          to="teams"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          onClick={props.handleClick}
        >
          Our Team
        </Link>
      </li>
      <li>
        <Link
          to="testimonial"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          onClick={props.handleClick}
        >
          Testimonial
        </Link>
      </li>
      <li>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          onClick={props.handleClick}
        >
          Contact Us
        </Link>
      </li>
    </ul>
  );
};
